import clsx from "clsx";
import * as CurrencyTextInput from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { Icon } from "../../Icon";

// https://www.npmjs.com/package/react-currency-input-field
// if we start accepting multiple currencies we can configure the locale with a custom config

export type PropsT = {
  id?: string;
  name?: string;
  label?: string;
  value?: string | number;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onChange?:
    | ((
        value: string | undefined,
        name?: string | undefined,
        values?: CurrencyInputOnChangeValues | undefined
      ) => void)
    | undefined;
  wide?: boolean;
  className?: string;
  defaultValue?: string | number;
  "data-testid"?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  wrapperClassName?: string;
};

export const CurrencyInput = (props: PropsT) => {
  const {
    id,
    name,
    label,
    value,
    hint,
    placeholder = "",
    disabled = false,
    error,
    onChange,
    wide,
    defaultValue,
    "data-testid": dataTestId,
    inputRef,
    className = "",
    wrapperClassName = "",
  } = props;

  const idForInput = id || label;

  return (
    <div
      className={clsx("CurrencyInput relative w-full", {
        "w-80": !wide,
        [wrapperClassName]: wrapperClassName,
      })}
    >
      <CurrencyTextInput.default
        className={clsx(
          "z-10 block w-full appearance-none rounded-lg border border-gray-300 bg-white pb-2.5 pl-[28px] pt-4", // input field
          "peer placeholder:text-transparent focus:border-gray-900 focus:pb-2.5 focus:pt-4 focus:outline-none focus:ring-0", // input state
          "overflow-ellipsis text-sm text-gray-900 autofill:text-sm autofill:text-gray-900", // text
          {
            "!focus:border-gray-100 !active:border-gray-100 cursor-default !border-gray-100 !bg-gray-100":
              disabled,
            "!border-orange-600 !bg-orange-100": error,
            "pr-10": error,
            [className]: className,
          }
        )}
        ref={inputRef}
        id={idForInput}
        name={name}
        value={value}
        onValueChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        allowNegativeValue={false}
        decimalsLimit={2}
        decimalScale={2}
        data-testid={dataTestId || "currency-input"}
      />

      <label
        htmlFor={idForInput}
        className={clsx(
          "absolute left-4 top-3.5 cursor-text text-sm text-gray-500 duration-300",
          "origin-[0] -translate-y-3 scale-75 transform peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-autofill:-translate-y-3 peer-autofill:scale-75 peer-focus:-translate-y-3 peer-focus:scale-75",
          "transition-all",
          {
            "!cursor-default text-gray-500": disabled,
          }
        )}
      >
        {label}
      </label>

      <div className="pointer-events-none absolute inset-y-0 left-0 mt-px flex scale-100 items-center pl-4 peer-placeholder-shown:scale-0 peer-autofill:scale-100 peer-focus:scale-100">
        <span className="text-sm text-gray-500">$</span>
      </div>

      {(hint || error) && <div className="h-1" />}

      {hint && <div className="text-xs text-gray-500">{hint}</div>}

      {error && (
        <div className="flex flex-row items-center text-sm font-medium text-orange-600">
          <Icon
            icon="warning-fill"
            size="16"
            iconStyle="fill-orange-600 mr-2"
          />
          {error}
        </div>
      )}
    </div>
  );
};
