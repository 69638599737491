"use client";

import clsx from "clsx";
import { ForwardedRef, InputHTMLAttributes, forwardRef, useState } from "react";
import { Button } from "../../Button";
import { Icon } from "../../Icon";

export type PropsT = {
  // all text inputs have these text fields
  "data-testid"?: string;
  name?: string;
  label?: string;
  id?: string;
  value?: string | number;
  hint?: string;
  autoComplete?: string; // auto complete values
  // some text inputs want these fields
  required?: boolean;
  error?: string | null;
  number?: boolean;
  // these are for types of inputs to render
  password?: boolean;
  percentage?: boolean;
  email?: boolean;
  phone?: boolean;
  website?: boolean;
  search?: boolean;
  wide?: boolean;
  clearable?: boolean;
  showRequired?: boolean;
  wrapperClassName?: string;
  // handlers
  onClear?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextInput = forwardRef(function TextInput(
  props: PropsT,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    name,
    label,
    id,
    value,
    hint,
    disabled,
    required,
    error,
    number,
    password,
    percentage,
    email,
    phone,
    website,
    search,
    onChange,
    autoComplete,
    showRequired,
    wide,
    onClear,
    "data-testid": dataTestId,
    clearable,
    className = "",
    wrapperClassName = "",
    ...inputProps
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const idForInput = id || label;

  const clearInput = () => {
    if (onChange)
      onChange({
        target: {
          value: "",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    onClear?.();
  };

  return (
    <div
      className={clsx("TextInput relative w-full max-w-full", {
        "w-full md:w-80": !wide,
        "!w-20": percentage,
        [wrapperClassName]: wrapperClassName,
      })}
    >
      {search && (
        <div className="pointer-events-none absolute left-0 flex items-center space-x-2 pl-4">
          {search && (
            <Icon
              icon="magnifying-glass"
              size="20"
              iconStyle="mt-3.5 fill-gray-400"
            />
          )}
        </div>
      )}

      <input
        {...inputProps}
        ref={ref}
        id={idForInput}
        name={name}
        pattern={number ? "[0-9]*" : undefined}
        type={
          password && !showPassword
            ? "password"
            : password && showPassword
              ? "text"
              : phone
                ? "tel"
                : email
                  ? "email"
                  : website
                    ? "url"
                    : number
                      ? "number"
                      : "text"
        }
        inputMode={number ? "decimal" : undefined}
        value={value}
        placeholder=" "
        disabled={disabled}
        // required={required}
        autoComplete={autoComplete ? autoComplete : undefined}
        onChange={onChange}
        onWheel={(e) => (e.target as HTMLElement).blur()}
        data-testid={dataTestId || `${name}-input`}
        className={clsx(
          "z-10 block w-full appearance-none rounded-lg border border-gray-300 bg-white px-[15px] pb-2.5 pt-4", // input field
          "peer placeholder:text-transparent focus:border-gray-900 focus:pb-2.5 focus:pt-4 focus:outline-none focus:ring-0", // input state
          "overflow-ellipsis text-sm text-gray-900 autofill:text-sm autofill:text-gray-900", // text
          {
            "!focus:border-gray-100 !active:border-gray-100 cursor-default !border-gray-100 !bg-gray-100":
              disabled,
            "!border-orange-600 !bg-orange-100": error,
            "pr-10": password || error,
            "pl-10": search,
            "pl-8": phone,
            [className]: className,
          }
        )}
      />

      <label
        htmlFor={idForInput}
        data-testid={`${dataTestId || name}-label`}
        className={clsx(
          "absolute left-4 top-3.5 cursor-text text-sm text-gray-500 duration-300",
          "origin-[0] -translate-y-3 scale-75 transform peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-autofill:-translate-y-3 peer-autofill:scale-75 peer-focus:-translate-y-3 peer-focus:scale-75",
          "transition-all",
          {
            "!cursor-default": disabled,
            "!left-10": search,
          }
        )}
      >
        {label}
        {showRequired && !value && (required ? " (required)" : " (optional)")}
      </label>

      {phone && (
        <div className="pointer-events-none absolute left-0 top-4 mt-px flex scale-100 items-center pl-4 peer-placeholder-shown:scale-0 peer-autofill:scale-100 peer-focus:scale-100">
          {phone && <span className="text-sm text-gray-500">+1</span>}
        </div>
      )}

      {(hint || error) && <div className="h-1" />}

      {clearable && value && (
        <div
          onClick={clearInput}
          className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2"
          data-testid="clear-button"
        >
          <Icon icon="x-circle" size="20" iconStyle="fill-gray-400" />
        </div>
      )}

      {hint && <div className="text-xs text-gray-500">{hint}</div>}

      {error && (
        <div className="flex flex-row items-center text-sm font-semibold text-orange-600">
          <Icon
            icon="warning-fill"
            size="16"
            iconStyle="fill-orange-600 mr-2"
          />
          {error}
        </div>
      )}

      {password && (
        <div className="absolute right-2 top-2.5 flex w-fit items-center">
          <Button
            color="transparent"
            customIconSize="20"
            size="sm"
            data-testid="password-button"
            icon={showPassword ? "eye-closed" : "eye"}
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
      )}

      {percentage && (
        <div className="absolute right-0 top-4 mt-px flex w-fit items-center pr-3">
          {percentage && <span className="text-sm text-gray-900">%</span>}
        </div>
      )}
    </div>
  );
});
